<template>
  <div class="collapseRow">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CollapseRow"
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.collapseRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include for-medium-up {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
</style>
