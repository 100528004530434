<template>
  <div class="headMovement">
    <div :class="axisClass" class="ghost"></div>
    <div :class="axisClass" class="sprite"></div>
    <p>{{ label }}</p>
  </div>
  <!-- END headMovement -->
</template>

<script>
export default {
  name: "HeadMovement",
  props: ["label"],
  computed: {
    axisClass: function () {
      if (this.label === "Yaw") {
        return "yaw";
      } else if (this.label === "Pitch") {
        return "pitch";
      } else if (this.label === "Roll") {
        return "roll";
      } else if (this.label === "X") {
        return "x";
      } else if (this.label === "Y") {
        return "y";
      } else if (this.label === "Z") {
        return "z";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.headMovement {
  width: 120px;
  box-sizing: border-box;
}
p {
  text-align: center;
  margin-top: -20px;
  padding-left: 1px;
}
.ghost {
  box-sizing: border-box;
  background-position: 50%;
  background-repeat: no-repeat;
  transform: scale(1);
  width: 120px;
  height: 138px;
  margin: 12px 0px 12px 1px;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 1s;
}
.headMovement:hover .ghost {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 1.5s;
}
.sprite {
  box-sizing: border-box;
  width: 100px;
  height: 138px;
  margin: 25px 0px 25px 2px;
  display: inline-block;
  margin-top: -175px;
  position: relative;
  background-position: 0;
  transform: scale(0.82);
}
.headMovement:hover .sprite {
  visibility: visible;
  animation: movehead 2s steps(30) infinite;
  transition-property: visibility;
  transition-duration: 1s;
}
.yaw.ghost {
  background-image: url($images-url + "6dof-yaw.png");
}
.yaw.sprite {
  background-image: url($images-url + "yaw-sprite.png");
}
.pitch.ghost {
  background-position: center 0;
  background-image: url($images-url + "6dof-pitch.png");
}
.pitch.sprite {
  background-image: url($images-url + "pitch-sprite.png");
}
.roll.ghost {
  background-image: url($images-url + "6dof-roll.png");
}
.roll.sprite {
  background-image: url($images-url + "roll-sprite.png");
}
.x.ghost {
  background-image: url($images-url + "6dof-x.png");
}
.x.sprite {
  background-image: url($images-url + "x-sprite.png");
}
.y.ghost {
  background-position: center 2px;
  background-image: url($images-url + "6dof-y.png");
}
.y.sprite {
  background-image: url($images-url + "y-sprite.png");
}
.z.ghost {
  transform: scale(1.2);
  background-image: url($images-url + "6dof-z.png");
}
.z.sprite {
  background-image: url($images-url + "z-sprite.png");
}

@keyframes movehead {
  from {
    background-position: left center;
  }
  to {
    background-position: -12000px;
  }
}
</style>
