<template>
  <div class="preciseTracking">
    <section class="preciseTrackingContent contentWrapper">
      <div class="preciseTrackingCopy">
        <h2>Precise Tracking</h2>
        <p>
          With the ability to focus on 1/150th of a pixel, TrackIR provides the
          most precise head tracking solution on the market.
        </p>
        <div class="specs">
          <span class="spec">
            <h2>51.7&deg;</h2>
            <p>Field of View</p>
          </span>
          <span class="spec">
            <h2>640 × 480</h2>
            <p>Raw Resolution</p>
          </span>
          <span class="spec">
            <h2>120 FPS</h2>
            <p>Sample Rate</p>
          </span>
          <span class="spec">
            <h2>9 MS</h2>
            <p>Response Time</p>
          </span>
        </div>
      </div>
    </section>
    <section class="preciseTrackingImage">
      <div class="imgWrapper">
        <img
          :src="imagesUrl + 'trackir5_head_green_lights@2x.png'"
          alt="Product Image"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { IMAGES_URL } from "@/constants/";
export default {
  name: "PreciseTracking",
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.preciseTrackingContent {
  box-sizing: border-box;
  padding-top: 55px;
  min-height: 500px;

  .preciseTrackingCopy {
    text-align: left;
    width: $tiny-width;

    h2 {
      text-align: center;
      margin-bottom: 0px;
    }

    p {
      text-align: center;
      margin-top: 0px;
    }

    @include for-medium-up {
      width: 500px;

      h2 {
        text-align: left;
      }
      p {
        text-align: left;
      }
    }
  }
}
.preciseTrackingImage {
  max-width: none;
  margin-left: auto;
  margin-right: auto;

  .imgWrapper {
    overflow: hidden;
    width: 100%;

    @include for-medium-up {
      margin-top: -413px;
      float: right;
    }

    img {
      position: relative;
      height: auto;
      width: 300px;
      margin: 40px 0 -10px;

      @include for-medium-up {
        float: left;
        left: 50%;
        margin: 0 0 0 150px;
        width: 500px;
      }
    }
  }
}
.specs {
  overflow: auto;
}
.spec {
  width: $tiny-width;
  float: none;
  margin: 5px auto;

  @include for-medium-up {
    width: 250px;
    float: left;

    h2 {
      font-size: 28px;
    }
  }

  h2 {
    font-size: 36px;
  }

  p {
    margin-top: -5px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 13px;
  }
}
</style>
