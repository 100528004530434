<template>
  <div class="forGamers">
    <section class="forGamersContent contentWrapper">
      <div class="forGamersCopy">
        <h2>
          Designed
          <br />For Gamers
        </h2>
        <p>
          Using the software’s advanced settings, you can adjust each axis
          individually to tailor the sensitivity and speed to fit your needs. Or
          use one of several pre-configured profiles to get started right away.
          By giving you control of the experience, TrackIR enables you to unlock
          the full potential of the game.
        </p>
      </div>
    </section>
    <section class="forGamersImage">
      <div class="imgWrapper">
        <img :src="imagesUrl + 'trackir-software.png'" alt="Product Image" />
      </div>
    </section>
  </div>
</template>

<script>
import { IMAGES_URL } from "@/constants/";
export default {
  name: "ForGamers",
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.forGamers {
  background: linear-gradient(0deg, #4a4a4a 0, #161616);
}

.forGamersContent {
  box-sizing: border-box;
  padding-top: 55px;

  @include for-medium-up {
    min-height: 453px;
  }

  .forGamersCopy {
    text-align: left;
    width: $tiny-width;

    h2 {
      margin-bottom: 0px;
      text-align: center;
    }
    p {
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      margin-top: 0px;
    }

    @include for-medium-up {
      margin-left: 300px;
      width: 420px;

      h2 {
        text-align: left;
      }

      p {
        text-align: left;
        max-width: 350px;
      }
    }
  }
}
.forGamersImage {
  max-width: none;
  margin-left: auto;
  margin-right: auto;

  .imgWrapper {
    overflow: hidden;
    width: 100%;
    margin-top: 40px;
    padding-bottom: 50px;

    @include for-medium-up {
      float: right;
      margin-top: -378px;
      padding-bottom: 0px;
    }

    img {
      position: relative;
      height: auto;
      width: $tiny-width;
      margin-right: 0px;

      @include for-medium-up {
        width: 583px;
        float: right;
        right: 50%;
        margin-right: 150px;
      }
    }
  }
}
</style>
