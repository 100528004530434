export default {
  computed: {
    saleOccuring: function () {
      
      let currentDate = new Date();
      
      // For testing - remove
      //currentDate = new Date('August 19, 2024');

      /* Sale Dates */
      let bfStart = new Date('November 29, 2024');
      let bfEnd = new Date('November 30, 2024');
      let bfExtendStart = new Date('November 30, 2024');
      let bfExtendEnd = new Date('December 2, 2024');
      let cyberStart = new Date('December 2, 2024');
      let cyberEnd = new Date('December 3, 2024');

      if (currentDate >= bfStart && currentDate < bfEnd) {
        return "Black Friday Sale";
      } else if (currentDate >= bfExtendStart && currentDate < bfExtendEnd) {
        return "Extended Black Friday Sale";
      } else if (currentDate >= cyberStart && currentDate < cyberEnd) {
        return "Cyber Monday Sale";
      } else {
        return null;
      }
    }
  },
};