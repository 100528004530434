<template>
  <a class="externalVideo" target="_blank" :href="link">
    <figure>
      <img :src="getImgUrl(screenshot)" :alt="label" />
      <figcaption>
        <span>{{ label }}</span>
        <span>Watch the Video</span>
        <span class="icon-play-circled"></span>
      </figcaption>
    </figure>
  </a>
  <!-- END externalVideo -->
</template>

<script>
import { IMAGES_URL } from "@/constants/";
export default {
  name: "ExternalVideo",
  props: ["label", "link", "screenshot"],
  methods: {
    getImgUrl(pic) {
      return IMAGES_URL + pic;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.externalVideo {
  display: block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.35);
  align-self: center;
}
figure {
  width: 220px;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 100%;
    height: auto;
  }
}
img {
  border-radius: 10px;
  padding-bottom: 15px;
  border: 0;
}
span:first-child {
  display: block;
  color: #ffffff;
  font-weight: 700;
}
</style>
