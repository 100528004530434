<template>
  <div class="trackIR5">
    <div class="contentWrapper">
      <h1>TrackIR 5</h1>
      <p>
        TrackIR is a head tracking input device designed for use with gaming and
        simulation PC titles. It is an infrared camera that tracks the position
        and orientation of your head, and then sends that data to any of the
        <router-link to="/games"
          >100+ TrackIR Enhanced games and simulations</router-link
        >.
      </p>
      <p>
        The TrackClip, included with every TrackIR 5 purchase, attaches to the
        brim of a hat or visor and has three retro-reflective markers that
        direct infrared light back to the camera. The
        <router-link to="/accessories">TrackClip Pro</router-link>, which
        attaches to most PC headsets, comes with active LED markers and is
        useful when you need to track from further away.
      </p>
    </div>
    <div class="monitor">
      <section class="sixdoF contentWrapper">
        <h2>6 Degrees of Freedom</h2>
        <p>
          TrackIR tracks all six directions a player’s head can move in 3D
          space, with each direction on a separate axis so that actual movements
          are accurately linked to the in-game view.
        </p>
        <div class="heads">
          <head-movement label="Yaw"></head-movement>
          <head-movement label="Pitch"></head-movement>
          <head-movement label="Roll"></head-movement>
          <head-movement label="X"></head-movement>
          <head-movement label="Y"></head-movement>
          <head-movement label="Z"></head-movement>
        </div>
        <div class="sixdoF-videos">
          <two-wide>
            <external-video
              label="TrackIR Explained"
              screenshot="trackir-explained-classic.png"
              link="https://www.youtube.com/watch?v=_AO0F5sLdVM"
            ></external-video>
            <external-video
              label="Head Tracking in Arma 2"
              screenshot="arma-2-demo-classic.png"
              link="https://www.youtube.com/watch?v=9wXx3vMy_AQ"
            ></external-video>
          </two-wide>
        </div>
      </section>
    </div>
    <precise-tracking></precise-tracking>
    <for-gamers></for-gamers>
    <fully-adjustable></fully-adjustable>
    <div class="tech-specs">
      <div class="tech-specs-content contentWrapper">
        <h2>Tech Specs</h2>
        <div class="tech-specs-images">
          <img
            :src="imagesUrl + 'trackir-5-front-specs-bare@2x.png'"
            alt="TrackIR measures 2 inches wide excluding the base"
          />
          <img
            :src="imagesUrl + 'trackir-5-side-specs-bare@2x.png'"
            alt="TrackIR measures point five seven inches deep and one point five inches high excluding the base"
          />
        </div>
        <div class="techSpecsData">
          <collapse-grid>
            <div class="label">Raw Sensor Resolution:</div>
            <div class="value">640 × 480</div>
            <div class="label">Reporting Resolution:</div>
            <div class="value">96,000 × 72,000</div>
            <div class="label">Field of View:</div>
            <div class="value">51.7&deg;</div>
            <div class="label">Resolution/Horizontal Degree:</div>
            <div class="value">1850 subpixels/degree</div>
            <div class="label">Sample Rate:</div>
            <div class="value">120 fps</div>
            <div class="label">Size (without base):</div>
            <div class="value">2" × 1.5" × 0.57"</div>
            <div class="label">Weight:</div>
            <div class="value">1.8 oz</div>
            <div class="label">Response Time:</div>
            <div class="value">9 ms</div>
            <div class="label">Connection:</div>
            <div class="value">USB</div>
            <div class="label">Cable Length:</div>
            <div class="value">6 feet</div>
          </collapse-grid>
          <ul>
            <li>In the Box</li>
            <li>TrackIR 5 hardware</li>
            <li>TrackClip</li>
            <li>
              <router-link to="/downloads/"
                >Quick Start Guide (view PDF)</router-link
              >
            </li>
          </ul>
          <ul>
            <li>System Requirements</li>
            <li>Available USB port</li>
            <li>Windows Vista or newer</li>
            <li>60 Megabytes of free hard drive space</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- END tech-specs -->
    <div class="vsBreakdown dark">
      <div class="vsBreakdownContent contentWrapper">
        <h2>
          TrackIR 4
          <span>vs.</span> TrackIR 5
        </h2>
        <h3>A Detailed Breakdown</h3>
        <collapse-row>
          <div class="label"></div>
          <div class="value header">TrackIR 4</div>
          <div class="value header">TrackIR 5</div>
        </collapse-row>
        <collapse-row>
          <div class="label">MSRP</div>
          <div class="value">$99.95</div>
          <div class="value">$149.95</div>
        </collapse-row>
        <collapse-row>
          <div class="label">Head Position Tracked</div>
          <div class="value">6 Axes (6 DOF)</div>
          <div class="value">6 Axes (6 DOF)</div>
        </collapse-row>
        <collapse-row>
          <div class="label">Horizontal Field of View</div>
          <div class="value">46&deg;</div>
          <div class="value highlight">51.7&deg;</div>
        </collapse-row>
        <collapse-row>
          <div class="label">Sample Rate (FPS)</div>
          <div class="value">120</div>
          <div class="value">120</div>
        </collapse-row>
        <collapse-row>
          <div class="label">Raw Sensor Resolution</div>
          <div class="value">355 × 288</div>
          <div class="value highlight">640 × 480</div>
        </collapse-row>
        <collapse-row>
          <div class="label">Sub-pixel Resolution</div>
          <div class="value">1/20th pixel</div>
          <div class="value highlight">1/150th pixel</div>
        </collapse-row>
        <collapse-row>
          <div class="label">Reporting Resolution</div>
          <div class="value">7,100 × 5,760</div>
          <div class="value highlight">96,000 × 72,000</div>
        </collapse-row>
        <collapse-row>
          <div class="label">
            Resolution / Horizontal
            <br />Field of View Degree
          </div>
          <div class="value">
            154
            <br />subpixels/degree
          </div>
          <div class="value highlight">
            1850
            <br />subpixels/degree
          </div>
        </collapse-row>
        <collapse-row>
          <div class="label">Unit Size (without base)</div>
          <div class="value">
            0.5" deep
            <br />1.7" wide <br />1.1" high
          </div>
          <div class="value">
            0.57" deep
            <br />2.0" wide <br />1.5" high
          </div>
        </collapse-row>
        <collapse-row>
          <div class="label">Connection</div>
          <div class="value">USB</div>
          <div class="value">USB</div>
        </collapse-row>
        <collapse-row>
          <div class="label">USB Cable Thickness</div>
          <div class="value">2.5 mm</div>
          <div class="value">2.5 mm</div>
        </collapse-row>
      </div>
      <!-- END vsBreakdownContent -->
    </div>
    <!-- END vsBreakdown -->

    <div class="darkBG contentSpacing">
        <div class="contentWrapper">
          <product-details
            id="bundleDetails"
            class="bundle"
            theme="dark"
            :imgRight="true"
            label="Pro Bundle"
            img="pro-bundle@2x.png"
            promo="trackir5pro"
            coupon="NPK544CV640EK324CC01"
            v-if="!saleOccuring"
          >
          <p>Provides the option to use wireless tracking or more precise wired tracking.</p>
          <p>Includes:</p>
          <ul>
            <li>TrackIR 5 camera</li>
            <li>TrackIR camera base</li>
            <li>TrackClip for wireless tracking</li>
            <li>TrackClip Pro for more precise tracking</li>  
          </ul>
          </product-details>

          <product-details
            id="bundleDetails"
            class="bundle"
            theme="dark"
            :imgRight="true"
            label="Aviation Day Bundle"
            img="trackir-hat-bundle.png"
            promo="aviationday2024"
            coupon="NPK104CS700EV354CM67"
            v-if="saleOccuring==='Aviation Day Special'"
          >
          <p>Provides the option to use wireless tracking or more precise wired tracking.</p>
          <p>Includes:</p>
          <ul>
            <li>TrackIR 5 camera</li>
            <li>TrackIR camera base</li>
            <li>TrackClip for wireless tracking</li>
            <li>TrackClip Pro for more precise tracking</li>  
            <li>Black TrackIR hat</li>
          </ul>
          </product-details>
        </div>
    </div>

    <two-wide className="gap-medium">
      <product-card
        id="tir5Card"
        label="TrackIR 5"
        img="spotlight-trackir-5@2x.png"
        theme="box"
        :itemId="268"
      >
      <p>Includes:</p>
      <ul>
        <li>TrackIR 5 camera</li>
        <li>TrackIR camera base</li>
        <li>TrackClip for wireless tracking</li>
      </ul>
      </product-card>
      <product-card
        id="tcpCard"
        label="TrackClip Pro"
        img="trackclip-pro@2x.png"
        theme="box"
        :itemId="139"
      >
      <p>Increases the accuracy of the TrackIR 5 camera by pairing it with a wired TrackClip Pro.</p>
      </product-card>
    </two-wide>

  </div>
  <!-- END trackIR5 -->
</template>
<script>
import { IMAGES_URL } from "@/constants/";
import HeadMovement from "@/components/HeadMovement.vue";
import ExternalVideo from "@/components/ExternalVideo.vue";
import TwoWide from "@/components/Layouts/TwoWide.vue";
import FullyAdjustable from "@/components/FullyAdjustable.vue";
import CollapseGrid from "@/components/CollapseGrid.vue";
import CollapseRow from "@/components/Layouts/CollapseRow.vue";
import ProductCard from "@/components/ProductCard.vue";
import ProductDetails from "@/components/ProductDetails.vue";
import PreciseTracking from "@/components/PreciseTracking.vue";
import ForGamers from "@/components/ForGamers.vue";
import mixin from "@/mixin";

export default {
  name: "TrackIR5",
  mixins: [mixin],
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
  components: {
    HeadMovement,
    ExternalVideo,
    TwoWide,
    FullyAdjustable,
    ProductCard,
    ProductDetails,
    CollapseGrid,
    CollapseRow,
    PreciseTracking,
    ForGamers,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.trackIR5 {
  text-align: center;
}
a {
  text-decoration: underline;
  color: #ffffff;
}
.monitor {
  text-align: center;
  background-position: 50% 43px, 0 265px, 0 270px;
  background-size: 370px, 100% 65px, auto;
  background-image: url($images-url + "trackir5-for-monitor.png"),
    url($images-url + "monitor-bezel.png"),
    radial-gradient(ellipse at top, #3b3b3b 0, #000 100%);
  background-repeat: no-repeat, repeat-x, no-repeat;
  padding-top: 290px;

  @include for-medium-up {
    background-size: 475px auto, 100% 65px, auto auto;
    background-position: 50% 44px, 0 330px, 0 344px;
    padding-top: 390px;
  }
}
.sixdoF {
  color: rgba(255, 255, 255, 0.5);
  p {
    width: $tiny-width;
    margin-left: auto;
    margin-right: auto;

    @include for-medium-up {
      width: 500px;
    }
  }
}
.heads {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding-bottom: 45px;

  @include for-medium-up {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
.sixdoF-videos {
  padding-top: 20px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.15);
}

.dark {
  background: linear-gradient(0deg, #4a4a4a 0, #161616);

  p {
    color: rgba(255, 255, 255, 0.5);
  }
}

.tech-specs {
  width: 100%;
  padding: 30px 0;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.techSpecsData {
  width: $tiny-width;
  padding-bottom: 30px;

  @include for-medium-up {
    float: right;
    margin-left: 20px;
    width: 460px;
    padding-bottom: 0px;
  }

  .collapseGrid {
    margin-top: 20px;
    div:nth-child(odd) {
      padding-top: 16px;
      font-weight: 700;
      color: #ffffff;
    }

    @include for-medium-up {
      margin-top: 0px;
      div:nth-child(odd),
      div {
        padding-top: 0px;
        padding-bottom: 16px;
        color: inherit;
        font-weight: inherit;
      }
    }
  }

  ul {
    display: block;
    margin: 0px;
    padding: 40px 0 0;
    list-style: none;
    width: 100%;

    li {
      text-align: left;

      &:first-child {
        font-weight: 700;
        color: #ffffff;
      }
    }

    @include for-medium-up {
      display: table-cell;
      width: 50%;

      li {
        margin-bottom: 16px;
      }
    }
  }
}
.tech-specs-images {
  width: 200px;
  margin-left: auto;
  margin-right: auto;

  @include for-medium-up {
    float: left;

    img:first-child {
      margin-bottom: 100px;
    }
  }

  img {
    width: 200px;
    height: auto;

    &:first-child {
      margin-bottom: 40px;
    }
  }
}
.vsBreakdown {
  padding: 30px 0 60px;

  h2 {
    margin-bottom: 0px;
    span {
      text-transform: lowercase;
    }
  }
  h3 {
    margin-top: 0px;
    font-size: 16px;
    color: hsla(0, 0%, 100%, 0.35);
    margin-bottom: 43px;
  }

  .vsBreakdownContent {
    color: #979797;

    .collapseGrid {
      div:nth-child(1) {
        border-bottom: 0px;
      }
      div:nth-child(3n + 1) {
        border-bottom: 0px;
        padding: 11px 0 0;
      }
      div {
        border-bottom: 1px solid #979797;
        padding: 0 0 11px;
      }
      .label {
        color: #ffffff;
      }
      .highlight {
        border-bottom: 1px solid var(--color-highlight);
      }
      .header {
        color: var(--color-highlight);
      }

      @include for-medium-up {
        div {
          padding: 11px 0;
        }
        div:nth-child(1) {
          border-bottom: 1px solid #979797;
        }
        div:nth-child(3n + 1) {
          border-bottom: 1px solid #979797;
          padding: 11px 0;
        }
      }
    }
  }
}

.vsBreakdown {
  .collapseRow {
    .label {
      color: #ffffff;
      width: 100%;
      border-bottom: 0px;
      padding: 11px 0 0;
    }
    .highlight {
      border-bottom: 1px solid var(--color-highlight);
    }
    .header {
      color: var(--color-highlight);
    }
    div {
      box-sizing: border-box;
      width: 50%;
      border-bottom: 1px solid #979797;
      padding: 0 0 11px;
      text-align: left;
    }
    @include for-medium-up {
      .label {
        border-bottom: 1px solid #979797;
      }
      .label,
      div {
        width: 33.3%;
        padding: 11px 0;
      }
    }
  }
}
</style>

