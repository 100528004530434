<template>
  <div class="fullyAdjustable">
    <section class="fullyAdjustableContent contentWrapper">
      <div class="fullyAdjustableCopy">
        <h2>Fully Adjustable</h2>
        <p>
          A magnetically attached base facilitates an easy and secure setup,
          with adjustable legs to fit any monitor.
        </p>
      </div>
    </section>
    <section class="fullyAdjustableImage">
      <div class="imgWrapper">
        <img
          :src="imagesUrl + 'trackir_5_perspective.png'"
          alt="Product Image"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { IMAGES_URL } from "@/constants/";
export default {
  name: "FullyAdjustable",
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.fullyAdjustableContent {
  box-sizing: border-box;
  padding-top: 55px;
  min-height: 500px;

  .fullyAdjustableCopy {
    text-align: left;
    padding-top: 220px;

    h2 {
      margin-bottom: 0px;
      text-align: center;
    }
    p {
      text-align: center;
      margin-top: 0px;
    }

    @include for-medium-up {
      width: 400px;
      padding-top: 0px;

      h2 {
        text-align: left;
      }

      p {
        text-align: left;
      }
    }
  }
}
.fullyAdjustableImage {
  max-width: none;
  margin-left: auto;
  margin-right: auto;

  .imgWrapper {
    float: right;
    overflow: hidden;
    width: 100%;
    margin-top: -440px;

    @include for-medium-up {
      margin-top: -480px;
    }

    img {
      position: relative;
      height: auto;
      width: 220px;
      float: none;
      left: none;
      margin-left: 0px;

      @include for-medium-up {
        width: 420px;
        float: left;
        margin-left: -50px;
        left: 50%;
      }
    }
  }
}
</style>
