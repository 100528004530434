<template>
  <div class="collapseGrid" :class="{ threeCols: cols === 3 }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CollapseGrid",
  props: {
    cols: { type: Number, default: 2 }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.collapseGrid {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(20, 1fr);
  text-align: left;

  @include for-medium-up {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(10, 1fr);
  }

  &.threeCols {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(24, 1fr);

    div {
      grid-column: auto / span 1;
    }

    div:nth-child(3n + 1) {
      grid-column: auto / span 2;
      -ms-grid-column-span: 2;
      background-color: red;
    }

    @include for-medium-up {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(12, 1fr);

      div:nth-child(3n + 1) {
        grid-column: auto / span 1;
      }
    }
  }
}
</style>
